<template>
  <a-card title="欣赏歌曲版本管理">
    <template #extra><a @click="handleBack">返回</a></template>
    <div class="tableToolBar">
      <a-button type="primary" @click="handleCreate">编辑开发版</a-button>
    </div>
    <a-table
      :dataSource="tableState.data"
      :columns="tableState.columns"
      rowKey="id"
      :pagination="tableState.pagination"
      :loading="tableState.loading"
      @change="handleTableChange"
      :scroll="{ x: 1300 }"
    >
      <template #action="{ record }">
        <div v-permission="PermissionMap.ENJOY_SONGS_VERSION">
          <a @click="handleJumpInfo(record)">
            详情
          </a>
          <a-divider type="vertical"/>
          <a-button class="noPadding" type="link" @click="setDev(record)" :disabled="record.version_type === 0">
            复制到开发版
          </a-button>
          <a-divider type="vertical"/>
          <a-button class="noPadding" type="link" @click="setTest(record)" :disabled="![0].includes(record.version_type)">
            设为测试版
          </a-button>
          <a-divider type="vertical"/>
          <a-button v-permission="PermissionMap.ENJOY_SONGS_PUBLISH" class="noPadding" type="link" @click="setProd(record)" :disabled="![1, 3].includes(record.version_type)">
            设为正式版
          </a-button>
          <a-divider v-permission="PermissionMap.ENJOY_SONGS_PUBLISH" type="vertical"/>
          <a-button type="link" @click="handleDelete(record)" :disabled="[1,2].includes(record.version_type)">
            删除
          </a-button>
        </div>
      </template>
      <template #tags="{ text }">
        <span v-if="!text?.length">--</span>
        <span v-else>
              <a-tooltip
                :title="text.map((tItem) => tItem.tag).toString()"
                placement="topLeft"
              >
                <span :key="item.tag" v-for="item in text">
                  <a-tag class="tag_item_label">{{ item.tag }}</a-tag>
                </span>
              </a-tooltip>
            </span>
      </template>
      <template #version_type="{ text }">
        <resource-version-tag :status="text"/>
      </template>
    </a-table>
  </a-card>
</template>

<script>
import { useRouter, useRoute } from 'vue-router'
import { message } from 'ant-design-vue'
import { PermissionMap } from '@/utils/auth'
import { computed, onBeforeMount, reactive, ref } from 'vue'
import { debounce } from '@/utils/common'
import {
  getEnjoySongVersions,
  deleteEnjoySongVersion,
  setEnjoyVersionTest,
  setEnjoyVersionDevelop,
  setEnjoyVersionProduction
} from '@/services/songs'
import ResourceVersionTag from '@/components/common/ResourceVersionTag'

export default {
  name: 'VersionList',
  components: {
    ResourceVersionTag
  },
  setup () {
    const router = useRouter()
    const route = useRoute()
    const pageData = reactive({
      type: 1,
      firstLoading: true,
      advanced: false,
      tagParams: {
        pageSize: 50,
        page: 1,
        keyword: ''
      },
      modalVisible: false
    })
    const tableState = reactive({
      data: [],
      columns: [],
      loading: true,
      pagination: {
        current: 1,
        total: 1,
        pageSize: 10,
        showTotal: total => `共${total}条`,
        showQuickJumper: true
      }
    })
    tableState.columns = [
      {
        title: 'ID',
        dataIndex: 'id',
        width: 120
      },
      {
        title: '歌曲名称',
        dataIndex: 'name',
        ellipsis: true,
        width: 200
      },
      {
        title: '歌曲识别码',
        dataIndex: 'song_code',
        width: 140
      },
      {
        title: '歌曲简拼',
        dataIndex: 'py',
        width: 100,
        ellipsis: true
      },
      {
        title: '版本类型',
        dataIndex: 'version_type',
        width: 200,
        slots: { customRender: 'version_type' }
      },
      {
        title: '创建时间',
        dataIndex: 'created_at',
        width: 180,
        ellipsis: true
      },
      {
        title: '更新时间',
        dataIndex: 'updated_at',
        width: 180,
        ellipsis: true
      },
      {
        title: '操作',
        key: 'action',
        fixed: 'right',
        width: 440,
        slots: { customRender: 'action' }
      }
    ]

    const getList = debounce(async (params) => {
      tableState.loading = true
      params = { page: 1, ...params, enjoy_song_id: route.params.id }
      const { items, pagination } = await getEnjoySongVersions(params)
      tableState.data = items
      tableState.loading = false
      tableState.pagination = { ...tableState.pagination, ...pagination }
    })
    const handleBack = () => {
      history.go(-1)
    }
    const handleJumpInfo = (record) => {
      router.push(`/resource/enjoy-songs/${route.params.id}/version-detail/${record.id}`)
    }
    const handleTableChange = (pag) => {
      tableState.pagination = pag
      getList({ page: tableState.pagination.current })
    }
    const handleCreate = () => {
      router.push(`/resource/enjoy-songs/${route.params.id}/edit/${pageData.type}`)
    }
    const handleDelete = async (record) => {
      await deleteEnjoySongVersion(record.id)
      message.success('操作成功')
      getList()
    }

    onBeforeMount(async () => {
      await Promise.all([
        getList()
      ])
      pageData.firstLoading = false
    })

    const setTest = async (record) => {
      await setEnjoyVersionTest(record.id)
      message.success('设置成功')
      getList()
    }
    const setDev = async (record) => {
      await setEnjoyVersionDevelop(record.id)
      message.success('设置成功')
      getList()
    }
    const setProd = async (record) => {
      await setEnjoyVersionProduction(record.id)
      message.success('设置成功')
      getList()
    }

    return {
      tableState,
      pageData,
      PermissionMap,
      handleJumpInfo,
      handleBack,
      handleTableChange,
      handleCreate,
      handleDelete,
      setTest,
      setProd,
      setDev
    }
  }
}
</script>

<style lang="less" scoped>
.noPadding{
  padding: 0;
}
.tableToolBar{
  margin-bottom: 12px;
}
</style>
